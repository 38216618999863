import React from "react"

import { Container, Col } from 'reactstrap'
import Link from '../../components/link'
import LayoutEsp from '../../components/layoutEsp'
import SEO from "../../components/seo"
import Slider from '../../components/slider-operator'
import Box from '../../components/box'
import { FaHome } from 'react-icons/fa';
import styled from 'styled-components'
import worldIcon from '../../assets/images/world.png'
import truckAbsolute from '../../assets/images/owner-truck.png' 

let StyledBackground = styled.div`
  background: linear-gradient(to bottom,#f9fbfd 0,#fff 100%);
`

let Service = ({title, Icon = FaHome}) => (
  <Col>
    <Link to="/">
      <Box>
        <Icon size={30}/>
        <h4 className="mt-3">{title}</h4>
      </Box>
    </Link>
  </Col>
)

export default () => (
  <LayoutEsp>
    <SEO title="Propietario Operador" />
    <Slider/>
	<div className="world-brand" >
		<Container className="pt-4">
			<div className="row">
			  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
				<img className="world-icon" src={worldIcon} alt="worldIcon"/>
			  </div>
			  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
				<h4 className="surpassing"><b>SUPERANDO</b> TODOS LOS LIMITES DE <b>NOSOTROS</b> A <b>TI</b></h4> 
			  </div>
			</div>
		</Container>
	</div>
	
	<div className="point-crossed-background2" >
		<Container className="pt-4">
			<div className="row">
			  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
				<h4 className="serviceQuality">{'PROPIETARIO OPERADOR'}</h4>  
				<p className="grayText3"> Creemos en la <b>mejora continua</b></p>  
				<img className="truckAbsolute6" src={truckAbsolute} alt="truck"/> 
			  </div> 
			  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12"> 
				<div  className="col-lg-12 col-md-12 col-sm-12 col-xs-12 whiteBack">  
					<p className="grayText2">{'Forma parte de una de las empresas de transporta más exitosas del '+
											  'Noroeste de México sin perder auntonomía como dueño de tu camión.\n\n '+
											  'En Gutierrez Trucking te ofrecemos el soporte de toda nuestra red para que '+
											  'tu como chofer propietario te sientas respaldado en cada traslado que realizes. \n\n'+
											  'Te brindamos la facilidad de designar tus horas en el camino y aceptar solo las cargas que tu desees.' 
											}</p> 
					<p className="grayText2"><b>¿ Listo para ser un chofer prpietario en Gutierrez Trucking ? </b> </p> 
					<div className="col-12 left colLeft">											
						<a className="locationVisit left">{'Requisitos'}</a>
					</div>
					<div className="col-12 left colLeft">
						<a className="locationVisit left">{'Compenzaciones'}</a> 
					</div>
					<div className="col-12 left colLeft">	
						<a className="locationVisit left">{'Requerimientos Regulatorios'}</a>
					</div>
					<div className="col-12 left colLeft">	 
						<a className="locationVisit left">{'Aplica Ahora'}</a> 
					</div> 
				</div>
			  </div> 
			</div>
		</Container>
	</div>
  </LayoutEsp>
)
